import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

import { Icon } from "@types";

const Logo: Icon = ({ fill = "white" }) => {
  return (
    <LogoContainer>
      <svg viewBox="0 0 271 163" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M84.9961 2.80859C82.6328 3.02344 79.9473 3.13086 77.3691 3.13086C74.6836 3.13086 72.1055 3.02344 69.7422 2.80859L19.6836 131.5C13.4531 147.721 7.00781 153.414 0.5625 154.596V159C4.9668 158.57 10.9824 158.355 17.4277 158.355C26.5586 158.355 34.8301 158.57 44.8203 159V154.596C32.7891 154.273 25.2695 151.266 25.2695 139.664C25.2695 133.863 27.0957 126.021 31.2852 115.279L37.1934 100.025H84.9961L98.209 136.119C99.9277 140.523 100.787 143.961 100.787 146.539C100.787 152.555 95.8457 154.166 85.3184 154.596V159C92.623 158.57 105.191 158.355 118.834 158.355C130.865 158.355 143.541 158.57 152.242 159V154.596C148.053 154.381 142.467 151.051 138.385 140.523L84.9961 2.80859ZM83.3848 95.6211H38.8047L61.5781 36.4316L83.3848 95.6211ZM213.15 0.445312C187.369 0.445312 163.844 12.9062 163.844 40.6211C163.844 65.1133 182.105 77.4668 203.053 90.7871C222.389 103.248 236.031 113.668 236.031 131.715C236.031 148.15 224.107 156.314 208.746 156.314C180.602 156.314 170.504 130.426 168.678 101.1H163.629C163.629 136.119 163.414 150.191 162.77 160.504H167.818C168.678 154.166 169.752 150.836 172.652 150.836C174.371 150.836 176.627 151.695 180.172 153.951C187.584 157.926 197.252 162.115 215.514 162.115C245.055 162.115 270.084 148.258 270.084 119.254C270.084 95.4062 253.756 83.4824 232.916 69.625C208.316 53.082 194.889 45.8848 194.889 28.1602C194.889 14.1953 204.986 5.70898 219.059 5.70898C242.584 5.70898 254.938 29.6641 256.441 52.5449H261.49C261.49 21.6074 261.92 11.0801 262.672 2.37891H257.623C256.656 8.7168 255.582 12.0469 252.682 12.0469C250.748 12.0469 248.814 11.0801 245.27 8.93164C238.395 4.84961 230.338 0.445312 213.15 0.445312Z"
          fill={fill === "#000" ? "#303233" : "white"}
        />
      </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  svg {
    height: 25px;
  }

  &:hover {
    opacity: 0.7;
    transition: 0.5s all;
  }

  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;
